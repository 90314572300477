// @ts-check

import PropTypes from "prop-types";
import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../contexts/dates";
import {
    changeHighlightedOp,
    changeHoveredOp,
    changeSelectedOp,
    loadDetailOpAction,
    saveCoreValues,
    saveOpRooms,
    savePosition,
    setScrollTo
} from "../../pages/op_management/op_management_actions";
import {
    selectAcceptedSchedule,
    selectCoreValues,
    selectHighlightedOp,
    selectLoadStatus,
    selectOpenSchedule,
    selectOpRooms,
    selectPosition,
    selectScrollTo,
    selectSelectedDate
} from "../../pages/op_management/op_management_selectors";
import {selectData} from "../../pages/timeslots/timeslots_selectors";
import {selectCurrentOrganizationId, selectShowFullMainMenu} from "../../redux/app_selectors";
import {isResolved} from "../../redux/utils/status";
import checkRoomsFilter from "../../utils/check_rooms_filter";
import getDiscipline from "../../utils/get_discipline";
import getRoom from "../../utils/get_room";
import getTimestamps from "../../utils/get_timestamps";
import {selectThemeStartTimeOfTheDay} from "../fe_settings/fe_settings_selectors";
import {OpDetailsPopover} from "../op_details_popover/op_details_popover";
import OpEditLayer from "../op_edit_layer/op_edit_layer";
import {clearEditOpAction, fetchOptionsAction, loadEditOpAction, setNewPositionAction} from "../op_edit_layer/op_edit_layer_actions";
import {selectEditOpData, selectNewPosition} from "../op_edit_layer/op_edit_layer_selectors";
import {selectRoomInfos} from "../rooms/rooms_selectors";
import DetailDialog from "../shared/detail_dialog/detail_dialog";
import NoOp from "../shared/no_op/no_op";
import DisciplineSlotsOpManage from "./components/discipline_slots_op_manage";
import DropTarget from "./components/drop_target";
import OpBoxesContainer from "./components/op_boxes_container";
import VerticalLines from "./components/vertical_lines";
import YaxisElement from "./components/yaxis_element";
import useStyles from "./op_manage_canvas.styles";

/**
 * Renders the OpManageCanvas
 * @param {Object} props
 * @param {Function} props.onOpenDetails
 * @param {Boolean} props.showFullActionMenubar
 * @param {Boolean} props.openRightLayer
 * @param {Boolean} props.isBlockscreenVisible
 * @return {React.ReactElement} The left label of the current time line
 */
export const OpManageCanvas = ({onOpenDetails, showFullActionMenubar, openRightLayer, isBlockscreenVisible}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const ref = useRef(null);
    const dispatch = useDispatch();
    const {fromISO, getDT, startOf, areSame, diffDT, now, plusDT, format, formatFromISO} = useContext(DateContext);

    // Redux
    const publishedOps = useSelector(selectAcceptedSchedule);
    const newOps = useSelector(selectOpenSchedule);
    const selectedDate = useSelector(selectSelectedDate);
    const position = useSelector(selectPosition);
    const loadStatus = useSelector(selectLoadStatus);
    const newPosition = useSelector(selectNewPosition);
    const scrollTo = useSelector(selectScrollTo);
    const organizationId = useSelector(selectCurrentOrganizationId);
    const opRooms = useSelector(selectOpRooms);
    const roomInfos = useSelector(selectRoomInfos);
    const coreValues = useSelector(selectCoreValues);
    const highlightedOp = useSelector(selectHighlightedOp);
    const startTimeOfTheDay = useSelector(selectThemeStartTimeOfTheDay);
    const showFullMainMenu = useSelector(selectShowFullMainMenu);
    const timeslots = useSelector(selectData);
    const editOpData = useSelector(selectEditOpData);

    const [openOpEdit, setOpenOpEdit] = useState(false);
    const [publishedOpsByRooms, setPublishedOpsByRooms] = useState({});

    /** @type {[Object.<number, PlanBox[]>, Function]} The list of the rooms */
    const [newOpsByRooms, setNewOpsByRooms] = useState({});
    const [mouseScroll, setMouseScroll] = useState({
        isScrolling: false,
        scrollLeft: 0,
        scrollTop: 0,
        clientX: 0,
        clientY: 0
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const isPopoverOpen = Boolean(anchorEl);

    useEffect(() => {
        window.addEventListener("resize", saveElementScrollPosition);
        document.addEventListener("click", handleResetHighlight, {capture: true});

        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener("resize", saveElementScrollPosition);
            document.removeEventListener("click", handleResetHighlight, {capture: true});
        };
    }, []);

    useEffect(() => {
        saveElementScrollPosition();
    }, [showFullMainMenu, opRooms, showFullActionMenubar, openRightLayer]);

    useEffect(() => {
        analyzeOpData();
    }, [newOps, publishedOps]);

    useEffect(() => {
        // @TODO #14846: without setTimeout would be ideal
        setTimeout(() => saveElementScrollPosition(), 500);
    }, [coreValues.planSetting]);

    useEffect(() => {
        if (ref.current && scrollTo) {
            // @TODO #14846: without setTimeout would be ideal
            setTimeout(() => scrollToSelectedOp(), 500);
        }
    }, [ref.current, scrollTo]);

    useEffect(() => {
        if (ref.current && !scrollTo) {
            scrollToStartHour();
        }
    }, [ref.current]);

    useEffect(() => {
        // if block screen is visible, force to close the edit layer
        if (isBlockscreenVisible) {
            dispatch(clearEditOpAction());
            dispatch(setNewPositionAction());
            setOpenOpEdit(false);
        }
    }, [isBlockscreenVisible]);

    /**
     * scroll to start business hour
     */
    const scrollToStartHour = () => {
        const scrollLeft = (startTimeOfTheDay || 8) * coreValues.widthPerHour;
        const maxScrollLeft = ref.current.scrollWidth - ref.current.clientWidth;
        ref.current.scrollLeft = scrollLeft > maxScrollLeft ? maxScrollLeft : scrollLeft;
    };
    /**
     * save element height/width and scrollHeight/Width
     */
    const saveElementScrollPosition = () => {
        if (ref && ref.current) {
            dispatch(
                savePosition({
                    scrollHeight: ref.current.scrollHeight,
                    scrollTop: ref.current.scrollTop,
                    clientHeight: ref.current.clientHeight,
                    clientWidth: ref.current.clientWidth,
                    scrollWidth: ref.current.scrollWidth,
                    scrollLeft: ref.current.scrollLeft,
                    offsetWidth: ref.current.offsetWidth,
                    devicePixelRatio: window.devicePixelRatio
                })
            );
        }
    };

    /**
     * Reset highlighted op
     * @param {Object} e event object
     */
    const handleResetHighlight = (e) => {
        // If icon is clicked, do not reset highlight
        const isIconClicked = e.target.closest("path") || e.target.closest("svg") || e.target.closest("span");
        if (Object.keys(highlightedOp).length !== 0 && !isIconClicked) {
            dispatch(changeHighlightedOp({}));
        }
    };

    /**
     * scroll to selectedOp
     */
    const scrollToSelectedOp = () => {
        if (!highlightedOp.id || !highlightedOp.opRoom || !highlightedOp.opStart) return;

        // Calculate scroll top position
        let scrollTopPosition = 0;

        const factor = coreValues.planSetting === "both" ? 1 : 0.5;

        const rowNumber = opRooms.indexOf(highlightedOp.opRoom);
        const maxScrollTop = position.scrollHeight - position.clientHeight;
        if (rowNumber > 1) {
            scrollTopPosition = (rowNumber - 1) * coreValues.rowHeight * factor;
            scrollTopPosition = scrollTopPosition > maxScrollTop ? maxScrollTop : scrollTopPosition;
        }

        // Calculate scroll left position
        const startHour = getDT(fromISO(highlightedOp.opStart), "hour") - 1;
        let scrollLeftPosition = startHour > 0 ? startHour * coreValues.widthPerHour : 0;
        const maxScrollLeft = position.scrollWidth - position.clientWidth;
        if (scrollLeftPosition > maxScrollLeft) {
            scrollLeftPosition = maxScrollLeft;
        }

        ref.current.scrollTop = scrollTopPosition;
        ref.current.scrollLeft = scrollLeftPosition;
        dispatch(setScrollTo(false));
    };

    /**
     * loop ops and returns
     * @param {Array<PlanBox>} ops
     * @return {{grouping: Object.<number, PlanBox[]>, disciplineRoomsMapping: Object.<string, string[]>, maxOpExitDT: DateTimeType}}
     */
    const analyzeOpRooms = (ops) => {
        let maxOpExitDT = startOf(selectedDate, "day");
        /** @type Object.<number, PlanBox[]> */
        const grouping = {}; // for rooms
        /** @type Object.<string, string[]> */
        const disciplineRoomsMapping = {}; // for disciplines vs rooms mapping

        for (const opData of ops) {
            const {roomLockEnd} = getTimestamps(opData);
            const opRoom = getRoom(opData);
            const discipline = getDiscipline(opData);

            if (opRoom !== null) {
                if (!grouping[opRoom]) {
                    grouping[opRoom] = [];
                }
                grouping[opRoom].push(opData);

                const roomLockEndDT = fromISO(roomLockEnd);
                if (roomLockEndDT > maxOpExitDT) {
                    maxOpExitDT = roomLockEndDT;
                }

                if (!disciplineRoomsMapping[discipline]) {
                    disciplineRoomsMapping[discipline] = [opRoom];
                } else {
                    disciplineRoomsMapping[discipline].push(opRoom);
                }
            }
        }

        return {grouping, disciplineRoomsMapping, maxOpExitDT};
    };

    /**
     * analyze op data
     */
    const analyzeOpData = () => {
        /** @type {Object.<string, string[]>} */
        const disciplineRoomsMapping = {};

        const {
            maxOpExitDT: newMaxOpExitDT,
            grouping: newGrouping,
            disciplineRoomsMapping: newDisciplineRoomsMapping
        } = analyzeOpRooms(newOps);
        const {
            maxOpExitDT: publishedMaxOpExitDT,
            grouping: publishedGrouping,
            disciplineRoomsMapping: publishedDisciplineRoomsMapping
        } = analyzeOpRooms(publishedOps);

        const maxOpExitDT = newMaxOpExitDT > publishedMaxOpExitDT ? newMaxOpExitDT : publishedMaxOpExitDT;

        setNewOpsByRooms(newGrouping);
        setPublishedOpsByRooms(publishedGrouping);

        // merge disciplineRoomsMapping
        const newOpsKeys = Object.keys(newDisciplineRoomsMapping);
        const opsResultsKeys = Object.keys(publishedDisciplineRoomsMapping);

        const disciplineKeys = [...new Set([...newOpsKeys, ...opsResultsKeys])];

        for (const discipline of disciplineKeys) {
            disciplineRoomsMapping[discipline] = [];
            if (newDisciplineRoomsMapping[discipline]) {
                disciplineRoomsMapping[discipline].push(...newDisciplineRoomsMapping[discipline]);
            }
            if (publishedDisciplineRoomsMapping[discipline]) {
                disciplineRoomsMapping[discipline].push(...publishedDisciplineRoomsMapping[discipline]);
            }
            disciplineRoomsMapping[discipline] = [...new Set(disciplineRoomsMapping[discipline])];
            disciplineRoomsMapping[discipline].sort();
        }

        // merge grouping
        const occupiedOpRooms = [...new Set(Object.keys(newGrouping).concat(Object.keys(publishedGrouping)))].sort();

        // check rooms to be shown according to roomsFilter and disciplinesFilter and save in opRooms
        const newRooms = checkRoomsFilter(
            coreValues.roomsFilter,
            coreValues.disciplinesFilter,
            occupiedOpRooms,
            disciplineRoomsMapping,
            roomInfos
        );

        dispatch(saveOpRooms(newRooms));

        let widthPerHour = 120; // default

        // Calculate how many hours to be rendered in the canvas
        const startOfSelectedDate = startOf(selectedDate, "day");
        // If the last surgery ends at 01:30 on the next day, the canvas will render 25 hours (until 02:00 next day)
        const canvasEnd = diffDT(maxOpExitDT, startOfSelectedDate, "hours") > 23 ? getDT(maxOpExitDT, "hour") + 24 : 23;

        // Check if window wider than 24 * 120px (in a state of both menus are closed)
        if ((canvasEnd + 1) * widthPerHour < window.innerWidth - 64 - 64) {
            widthPerHour = Math.floor((window.innerWidth - 64 - 64) / (canvasEnd + 1));
        }

        dispatch(
            saveCoreValues({
                ...coreValues,
                widthPerHour: widthPerHour,
                currentTime: now(),
                rowCount: newRooms.length,
                occupiedOpRooms: occupiedOpRooms,
                disciplineRoomsMapping: disciplineRoomsMapping
            })
        );
    };

    /**
     * Handler to close the popover with the OP Details
     */
    const handleClosePopover = () => {
        setAnchorEl(null);
        dispatch(changeSelectedOp());
    };

    /**
     * handle to open op details by clicking op
     * @param {object} object - Properties wrapper
     * @param {string} object.opId - The id of the appointment
     * @param {Element} object.currentTarget - The clicked element
     */
    const handleClick = ({currentTarget, opId}) => {
        dispatch(loadDetailOpAction(organizationId, opId));
        onOpenDetails(opId);

        const target = currentTarget;
        setAnchorEl(target);
    };

    /**
     * handle to switch edit mode
     * @param {string} opId
     * @param {String} procedureCode
     * @param {String} hcServiceId
     */
    const handleClickEdit = (opId, procedureCode, hcServiceId) => {
        setOpenOpEdit(true);
        dispatch(loadEditOpAction(opId));
        dispatch(fetchOptionsAction(procedureCode, hcServiceId));
    };

    /**
     * handle to exit edit mode
     */
    const handleStopEdit = () => {
        dispatch(clearEditOpAction());
        dispatch(setNewPositionAction());
        setOpenOpEdit(false);
    };

    /**
     * handle drop op
     * @param {Object} params
     * @param {string} params.opId
     * @param {number} params.yaxisLabelIndex in order to get the locationId
     * @param {number} params.movedX - how many pixel moved
     * @param {number} params.scrollLeft - the pixel scrolled while dragging
     * @param {String} params.procedureCode
     * @param {String} params.hcServiceId
     */
    const handleDropOp = ({opId, yaxisLabelIndex, movedX, scrollLeft, procedureCode, hcServiceId}) => {
        // Reset hovered  state
        dispatch(changeHoveredOp());

        const newOpData = newOps.find((op) => op.id === opId);

        if (newOpData !== undefined) {
            /** @type NewPosition */
            const newPosition = {opId, locationId: null, roomLockStart: null};
            // Set new room (regardless of it's changed)
            newPosition.locationId = opRooms[yaxisLabelIndex];

            const secondsPerPixel = (60 * 60) / coreValues.widthPerHour;

            // Calculate moved time in minutes
            const diffScroll = ref.current.scrollLeft - scrollLeft;
            const movedSeconds = Math.round(secondsPerPixel * (movedX + diffScroll));

            const startTime = newOpData._internalTimestamps.duraRoomLockPre?.dtStart;
            if (movedSeconds !== 0 && startTime) {
                newPosition.roomLockStart = plusDT(fromISO(startTime), "second", movedSeconds).toISO();
            }

            dispatch(setNewPositionAction(newPosition));
            dispatch(loadEditOpAction(opId));
            dispatch(fetchOptionsAction(procedureCode, hcServiceId));
            setOpenOpEdit(true);
        }
    };

    /**
     * handler for mouse down
     * @param {Object} e - event object
     */
    const handleMouseDown = (e) => {
        setMouseScroll({
            isScrolling: true,
            scrollLeft: ref.current.scrollLeft,
            scrollTop: ref.current.scrollTop,
            clientX: e.clientX,
            clientY: e.clientY
        });
    };
    /**
     * handler for mouse up
     */
    const handleMouseUp = () => {
        setMouseScroll({
            isScrolling: false,
            scrollLeft: 0,
            scrollTop: 0,
            clientX: 0,
            clientY: 0
        });
    };

    /**
     * handler for mouse move
     * @param {Object} e - event object
     */
    const handleMouseMove = (e) => {
        const {clientX, scrollLeft, scrollTop, clientY, isScrolling} = mouseScroll;
        if (isScrolling) {
            ref.current.scrollLeft = scrollLeft + (clientX - e.clientX);
            ref.current.scrollTop = scrollTop + (clientY - e.clientY);
        }
    };

    // Prepare for rendering
    const timelabels = [];
    for (let i = 0; i <= coreValues.canvasEnd; i++) {
        const label = (i % 24).toString().padStart(2, "0").concat(":00");
        const key = `${label}-${i}`;
        timelabels.push({label, key});
    }

    // Set past area and current time
    const rowHeight = coreValues.planSetting === "both" ? coreValues.rowHeight : coreValues.rowHeight / 2; // in px
    const canvasHeight = rowHeight * opRooms.length;
    let timeline = null;
    let past = null;
    if (coreValues.currentTime && areSame(coreValues.currentTime, selectedDate, "day")) {
        const diffMinutes = diffDT(coreValues.currentTime, startOf(now(), "day"), "minutes");
        const factor = coreValues.planSetting === "both" ? 1 : 0.5;
        const gridHeight = coreValues.rowHeight * factor * opRooms.length + 3;
        timeline = (
            <div
                className={classes.currentTimeLine}
                role="separator"
                style={{
                    left: (diffMinutes * coreValues.widthPerHour) / 60 + "px",
                    height: gridHeight + "px"
                }}
            >
                <div className={classes.currentTimeLabel}>{formatFromISO(coreValues.currentTime, DATE_FORMATS.TIME)}</div>
            </div>
        );
        past = (
            <div
                className={classes.pastArea}
                style={{
                    left: 0,
                    width: (diffMinutes * coreValues.widthPerHour) / 60 + "px",
                    height: canvasHeight + "px"
                }}
            />
        );
    } else if (coreValues.currentTime && diffDT(coreValues.currentTime, selectedDate, "days") > 0) {
        past = <div className={classes.pastArea} style={{left: 0, width: "100%", height: canvasHeight + "px"}} />;
    }
    // Show NoOp page if there are no ops planned
    if (isResolved(loadStatus) && (newOps?.length ?? 0) + (publishedOps?.length ?? 0) === 0) {
        return (
            <NoOp
                text1={t("App.notFoundOp1")}
                text2={t("App.notFoundOp2", {
                    date: format(selectedDate, DATE_FORMATS.DATE)
                })}
            />
        );
    }

    const rowHeightPerRoom = coreValues.rowHeight * (coreValues.planSetting === "both" ? 1 : 0.5);

    return (
        <Fragment>
            <div
                className={cx(classes.root, {
                    [classes.isScrolling]: mouseScroll.isScrolling
                })}
                ref={ref}
                role={"grid"}
                tabIndex={0}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onScroll={saveElementScrollPosition}
            >
                <OpDetailsPopover
                    anchorEl={anchorEl}
                    handleClosePopover={handleClosePopover}
                    isBlockscreenVisible={isBlockscreenVisible}
                    isPopoverOpen={isPopoverOpen}
                />
                <div className={classes.grid}>
                    <div className={classes.gridColRooms}>
                        <div className={cx(classes.gridColTimes, classes.room)}>{t("OpManagement.rooms")}</div>
                        <YaxisElement coreValues={coreValues} opRooms={opRooms} />
                    </div>
                    <div className={classes.content} style={{width: coreValues.widthPerHour * (coreValues.canvasEnd + 1) + "px"}}>
                        <div className={cx(classes.gridColTimes, classes.gridItemHeader)}>
                            <div className={classes.rowWrapper} data-testid="time-header">
                                {timelabels.map(({label, key}, i) => (
                                    <div
                                        className={classes.time}
                                        key={key}
                                        style={{
                                            left: i * coreValues.widthPerHour + "px",
                                            width: coreValues.widthPerHour + "px"
                                        }}
                                    >
                                        {label}
                                    </div>
                                ))}
                                {timeline}
                                <VerticalLines height="100%" />
                            </div>
                        </div>
                        {opRooms.map((room, i) => (
                            <div
                                className={classes.rowWrapper}
                                key={"canvas-row-" + room}
                                style={{
                                    height: rowHeight + "px"
                                }}
                            >
                                {i === 0 && past}
                                {i === 0 && <VerticalLines height={`${opRooms.length * rowHeightPerRoom}px`} />}
                                <DisciplineSlotsOpManage
                                    disciplineSlots={timeslots.filter((slot) => slot.locations?.length && slot.locations?.[0] === room)}
                                    rowHeight={rowHeightPerRoom}
                                    widthPerHour={coreValues.widthPerHour}
                                />
                                <DropTarget index={i} onDropOp={handleDropOp} />
                                <OpBoxesContainer
                                    newOps={newOpsByRooms[room] || []}
                                    publishedOps={publishedOpsByRooms[room] || []}
                                    onClick={handleClick}
                                    onClickEdit={handleClickEdit}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {openOpEdit && editOpData?.length && (
                <DetailDialog
                    isBlockscreenVisible={isBlockscreenVisible}
                    open={openOpEdit}
                    styles={{root: classes.detailDialogRoot}}
                    onClose={handleStopEdit}
                >
                    <OpEditLayer newPosition={newPosition} originalOpData={editOpData[0]} onClose={handleStopEdit} />
                </DetailDialog>
            )}
        </Fragment>
    );
};

OpManageCanvas.propTypes = {
    onOpenDetails: PropTypes.func.isRequired,
    openRightLayer: PropTypes.bool,
    showFullActionMenubar: PropTypes.bool,
    isBlockscreenVisible: PropTypes.bool.isRequired
};

export default OpManageCanvas;
