// @ts-check
import PropTypes from "prop-types";
import React, {Fragment, useContext} from "react";
import {useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {scheduleOp} from "../../../pages/op_management/op_management_proptypes";
import {
    selectCoreValues,
    selectOpRooms,
    selectSelectedDate,
    selectSessionEditedBy
} from "../../../pages/op_management/op_management_selectors";
import {selectCurrentUserEmail} from "../../../redux/app_selectors";
import {checkOpOverlap} from "../../../utils/check_op_overlap";
import getDiscipline from "../../../utils/get_discipline";
import getDurations from "../../../utils/get_durations";
import getInterventions from "../../../utils/get_interventions";
import {getParticipantCategories} from "../../../utils/get_participant_category";
import getRoom from "../../../utils/get_room";
import getTimestamps from "../../../utils/get_timestamps";
import {PERMISSION, useSecurity} from "../../../utils/security";
import {sortOpByPriorityAndStartTime} from "../../../utils/sort_op_by_priority_and_start_time";
import {selectFeSettings} from "../../fe_settings/fe_settings_selectors";
import {determineIsEditIconVisible, formatContents} from "../../op_box_horizontal/helpers";
import OpBoxHorizontal from "../../op_box_horizontal/op_box_horizontal";

const CPB = "CPB";
const barHeight = 3.8;

/**
 * Op boxes container
 * @param {Object} props
 * @param {Array<PlanBox>} props.newOps new plan
 * @param {Array<PlanBox>} props.publishedOps published plan
 * @param {Function} props.onClick
 * @param {Function} props.onClickEdit
 * @return {React.ReactElement}
 */
const OpBoxesContainer = ({newOps, publishedOps, onClick, onClickEdit}) => {
    const {fromISO, startOf, diffDT, getLuxonToken} = useContext(DateContext);
    const {isGranted} = useSecurity();

    // Redux
    const opRooms = useSelector(selectOpRooms);
    const coreValues = useSelector(selectCoreValues);
    const selectedDate = useSelector(selectSelectedDate);
    const {additionalOpInfo, participantCategoriesForHealthcareService, emergencyThreshold} = useSelector(selectFeSettings);
    const sessionEditedBy = useSelector(selectSessionEditedBy);
    const email = useSelector(selectCurrentUserEmail);

    if (!newOps || !publishedOps) return null;

    const handleDetailClick = ({currentTarget, opId}) => {
        onClick({currentTarget, opId});
    };
    const generateBars = (type, ops) => {
        const bars = [];

        for (const opData of ops) {
            if (coreValues.disciplinesFilter.length > 0 && !coreValues.disciplinesFilter.includes(getDiscipline(opData))) {
                continue;
            }
            const {roomLockStart, roomLockEnd} = getTimestamps(opData);
            const opEnter = fromISO(roomLockStart);
            const opExit = fromISO(roomLockEnd);

            const startOfDay = startOf(selectedDate, "day");
            const durationOpEnterSeconds = diffDT(opEnter, startOfDay, "seconds");
            const startPercent = (durationOpEnterSeconds / ((coreValues.canvasEnd + 1) * 60 * 60)) * 100; // 24 hours in seconds
            const durationOpExitSeconds = diffDT(opExit, startOfDay, "seconds");
            const endPercent = (durationOpExitSeconds / ((coreValues.canvasEnd + 1) * 60 * 60)) * 100; // 24 hours in seconds
            const rowNumber = opRooms.indexOf(getRoom(opData));

            const topPosition = type === "published" || coreValues.planSetting === "new" ? 8 : coreValues.rowHeight / 2 + 8;

            const hcServiceId = getDiscipline(opData);

            // extract properties
            const {
                id,
                _team: team,
                _patient: patient,
                _interventions: interventions,
                _isEditable: isEditable,
                _priority: priority,
                _status: status,
                _styles: styles,
                _internalTimestamps: internalTimestamps,
                _medicalApproval: medicalApproval,
                _surgeonPresenting: surgeonPresenting,
                _isLocked: isLocked
            } = opData;

            const luxonTokenTime = getLuxonToken(DATE_FORMATS.TIME);

            const contents = formatContents({
                team,
                participantCategories: getParticipantCategories({
                    participantCategoriesForHealthcareService,
                    hcServiceId
                }),
                patient,
                intervention: getInterventions(opData),
                internalTimestamps,
                isCpbVisible: additionalOpInfo?.includes(CPB),
                medicalApproval,
                surgeonPresenting,
                luxonTokenTime,
                isLocked
            });

            const shape = {
                barHeight,
                topPosition,
                startPercent,
                endPercent
            };
            const procedureCode = interventions.main?.[0].code;

            const isEditableWithPermission = isEditable && isGranted(PERMISSION.MODIFY_PLAN);
            const isEditIconVisible = determineIsEditIconVisible({
                isPublished: type === "published",
                isEditingByOther: sessionEditedBy && email !== sessionEditedBy
            });
            if (rowNumber !== -1) {
                const {hasOverlap, adjustIndex} = checkOpOverlap(opData, ops);
                bars.push(
                    <OpBoxHorizontal
                        adjustIndex={type === "new" ? adjustIndex : 0}
                        contents={contents}
                        duration={getDurations(opData)}
                        edit={{isEditable: isEditableWithPermission, isEditIconVisible}}
                        hasOverlap={type === "new" && hasOverlap}
                        hcServiceId={hcServiceId}
                        id={id}
                        isDeemphasized={type === "published"}
                        key={"newplan-" + type + id}
                        priority={priority}
                        procedureCode={procedureCode}
                        shape={shape}
                        showDiscipline
                        status={status}
                        styles={styles}
                        onClick={handleDetailClick}
                        onClickEdit={onClickEdit}
                    />
                );
            }
        }
        return bars;
    };

    const barsNew = generateBars("new", newOps.sort(sortOpByPriorityAndStartTime(emergencyThreshold)));
    const barsPublished =
        coreValues.planSetting === "both"
            ? generateBars("published", publishedOps.sort(sortOpByPriorityAndStartTime(emergencyThreshold)))
            : [];

    return (
        <Fragment>
            {barsPublished}
            {barsNew}
        </Fragment>
    );
};

OpBoxesContainer.propTypes = {
    newOps: PropTypes.arrayOf(scheduleOp),
    publishedOps: PropTypes.arrayOf(scheduleOp),
    onClick: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired
};

export default OpBoxesContainer;
