import {Box} from "@mui/material";
import {arrayOf, number, object} from "prop-types";
import React, {useContext} from "react";
import {useSelector} from "react-redux";

import {DateContext} from "../../../contexts/dates";
import {selectVisibleDisciplines} from "../../../pages/op_management/op_management_selectors";
import filterVisibleSlots from "../../../utils/filter_visible_slots";
import {selectDisciplineColors} from "../../fe_settings/fe_settings_selectors";
import useStyles from "../op_manage_canvas.styles";

const PADDING = 4;
/**
 * Renders DisciplineSlotsOpManage in op manage
 * @param {object} props
 * @param {DisciplineSlot[]} props.disciplineSlots
 * @param {number} props.widthPerHour
 * @param {number} props.rowHeight
 * @return {React.ReactElement} The line for the current time line
 */
export const DisciplineSlotsOpManage = ({disciplineSlots, widthPerHour, rowHeight}) => {
    const {classes, cx} = useStyles();
    const {fromISO, diffDT} = useContext(DateContext);
    // redux
    const visibleDisciplines = useSelector(selectVisibleDisciplines);
    const disciplineColors = useSelector(selectDisciplineColors);

    const visibleSlots = filterVisibleSlots(disciplineSlots, visibleDisciplines);
    const slotsJSX = [];
    for (const slot of visibleSlots) {
        const start = slot.preCalcDates[0].start;
        const end = slot.preCalcDates[0].end;
        const healthcareServiceId = slot.healthcareServices[0];
        const locationId = slot.locations?.length && slot.locations?.[0];

        const key = slot.isEmergency ? `emergency-${locationId}-${start}` : `${healthcareServiceId}-${locationId}-${start}`;

        const slotEnterHours = fromISO(start).hour;
        const slotEnterMinutes = fromISO(start).minute;
        const durationMinutes = diffDT(fromISO(end), fromISO(start), "minutes");

        // Calculate position from the top
        const top = PADDING;

        // Calculate box height
        const height = rowHeight - PADDING * 2;

        // Calculate position from the left
        const percOfMinutes = slotEnterMinutes / 60;
        const left = widthPerHour * slotEnterHours + widthPerHour * percOfMinutes;

        // Calculate the width of the box
        const width = widthPerHour * (durationMinutes / 60);

        // discipline color
        const disciplineColor = disciplineColors.find((item) => item.name === healthcareServiceId);

        slotsJSX.push(
            <Box
                className={cx(classes.visibleSlot, {
                    [classes.visibleSlotEmergency]: slot.isEmergency
                })}
                data-testid={`op-manage-discipline-slot-${healthcareServiceId}`}
                height={height + "px"}
                key={key}
                left={left + "px"}
                style={{backgroundColor: !slot.isEmergency && disciplineColor?.hex}}
                top={top + "px"}
                width={width + "px"}
            />
        );
    }

    return <div className={classes.visibleSlotWrapper}>{slotsJSX}</div>;
};

DisciplineSlotsOpManage.propTypes = {
    disciplineSlots: arrayOf(object), // see typedef
    widthPerHour: number,
    rowHeight: number
};

export default DisciplineSlotsOpManage;
