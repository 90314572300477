import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        position: "relative",
        height: "100%",
        width: "100%",
        background: "#fdfdfd",
        display: "grid",
        overflow: "auto",
        cursor: "grab"
    },
    isScrolling: {
        cursor: "grabbing"
    },
    grid: {
        display: "flex",
        flexWrap: "nowrap"
    },
    gridColTimes: {
        height: "1.875rem"
    },
    gridColRooms: {
        width: "4rem",
        position: "sticky",
        left: 0,
        background: theme.palette.background.paper,
        borderRight: `1px solid ${theme.palette.border}`,
        zIndex: theme.custom.zIndex.canvas + 140
    },
    roomLabel: {
        borderBottom: `1px solid ${theme.palette.border}`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "stretch"
    },
    roomLabelNew: {
        height: "5rem"
    },
    gridItemHeader: {
        height: "1.875rem",
        position: "sticky",
        background: theme.palette.background.paper,
        top: 0,
        zIndex: theme.custom.zIndex.canvas + 20
    },
    gridItem: {
        borderBottom: `1px solid ${theme.palette.border}`
    },
    rowWrapper: {
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        borderBottom: `1px solid ${theme.palette.border}`
    },
    room: {
        lineHeight: "1.875rem",
        textAlign: "center",
        fontSize: "0.75rem",
        color: theme.palette.grey[500],
        fontWeight: theme.typography.fontWeightMedium,
        position: "sticky",
        top: 0,
        background: theme.palette.background.paper,
        zIndex: theme.custom.zIndex.canvas + 150,
        borderBottom: `1px solid ${theme.palette.border}`
    },
    time: {
        position: "absolute",
        lineHeight: "1.875rem",
        fontSize: "0.75rem",
        flexGrow: 0,
        flexShrink: 0,
        color: theme.palette.grey[500],
        fontWeight: theme.typography.fontWeightMedium,
        textAlign: "center"
    },
    yaxisLabel: {
        fontSize: "0.625rem",
        color: theme.palette.text.secondary,
        textAlign: "center",
        fontWeight: theme.typography.fontWeightMedium,
        width: "4rem"
    },
    new: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        opacity: 0.9,
        objectFit: "contain",
        width: "2rem",
        fontWeight: theme.typography.fontWeightMedium,
        marginLeft: theme.spacing("m"),
        borderRadius: theme.spacing("xxs"),
        textTransform: "uppercase"
    },
    active: {
        color: theme.palette.grey[600],
        textAlign: "center",
        fontWeight: theme.typography.fontWeightMedium,
        textTransform: "uppercase",
        width: "100%"
    },
    roomBoth: {
        margin: `${theme.spacing("m")} 0`,
        fontSize: "20px",
        lineHeight: "2rem",
        color: theme.palette.grey[600]
    },
    roomOnlyNew: {
        margin: 0
    },
    vlines: {
        position: "absolute",
        background: theme.palette.border,
        width: "1px",
        zIndex: theme.custom.zIndex.canvas + 1
    },
    newArea: {
        width: "100%",
        position: "absolute",
        borderTop: `1px solid ${theme.palette.border}`,
        borderBottom: `1px solid ${theme.palette.border}`,
        zIndex: theme.custom.zIndex.canvas + 3
    },
    currentTimeLine: {
        position: "absolute",
        width: "1px",
        top: "27px",
        backgroundColor: theme.palette.primary.main,
        zIndex: theme.custom.zIndex.canvas + 130
    },
    currentTimeLabel: {
        position: "absolute",
        width: "2.5rem",
        height: "1.125rem",
        top: "-1.4rem",
        left: "-1.2rem",
        borderRadius: theme.spacing("xs"),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        border: `1px solid ${theme.palette.primary.contrastText}`,
        zIndex: theme.custom.zIndex.canvas + 130,
        fontSize: "0.75rem",
        fontWeight: theme.typography.fontWeightMedium,
        textAlign: "center"
    },
    pastArea: {
        position: "absolute",
        height: "100%",
        backgroundColor: theme.palette.primary.main,
        zIndex: theme.custom.zIndex.canvas + 1,
        opacity: "0.05"
    },
    canDrop: {
        opacity: 0.4,
        backgroundColor: theme.palette.primary.main,
        zIndex: theme.custom.zIndex.canvas + 3
    },
    visibleSlotWrapper: {
        position: "absolute",
        width: "100%",
        height: "100%"
    },
    visibleSlot: {
        position: "absolute",
        zIndex: theme.custom.zIndex.canvas + 2,
        opacity: "50%",
        borderRadius: "5px"
    },
    visibleSlotEmergency: {
        backgroundColor: theme.custom.emergency.main
    },
    detailDialogRoot: {
        maxWidth: "1100px",
        maxHeight: "fit-content",
        borderRadius: 0
    },
    content: {
        overflowX: "clip"
    }
}));
