import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {selectThemeLocationInfos} from "../../fe_settings/fe_settings_selectors";
import {selectRoomInfos} from "../../rooms/rooms_selectors";
import useStyles from "../op_manage_canvas.styles";

/**
 * render y axis elements
 * @param {object} coreValues
 * @param {array} opRooms
 * @return {JSX}
 */
export const YaxisElement = ({coreValues, opRooms}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();

    const roomInfos = useSelector(selectRoomInfos);
    const locationInfos = useSelector(selectThemeLocationInfos);

    const yaxisElements = [];

    if (!coreValues.rowHeight || opRooms.length === 0) return null;

    const factor = coreValues.planSetting === "both" ? 1 : 0.5;
    for (const [key, value] of opRooms.entries()) {
        const room = roomInfos.find((item) => item.id === value);
        const roomLabel = locationInfos?.[room?.id]?.label || room?.name || null;

        yaxisElements.push(
            <div className={classes.roomLabel} key={key} style={{height: coreValues.rowHeight * factor + "px"}}>
                {coreValues.planSetting === "both" && (
                    <div className={classes.yaxisLabel}>
                        <div className={classes.active}>{t("OpManagement.yaxisActive")}</div>
                    </div>
                )}
                <div className={classes.yaxisLabel}>
                    <div
                        className={cx(classes.roomBoth, {
                            [classes.roomOnlyNew]: coreValues.planSetting === "new"
                        })}
                        title={room ? room.name : value}
                    >
                        {room ? roomLabel : value}
                    </div>
                </div>
                <div className={classes.yaxisLabel}>
                    <div className={classes.new} data-testid="y-axis-new">
                        {t("OpManagement.yaxisNew")}
                    </div>
                </div>
            </div>
        );
    }
    return <Fragment>{yaxisElements}</Fragment>;
};
YaxisElement.propTypes = {
    opRooms: PropTypes.arrayOf(PropTypes.string),
    coreValues: PropTypes.shape({
        rowHeight: PropTypes.number,
        planSetting: PropTypes.oneOf(["both", "new"])
    })
};

export default YaxisElement;
