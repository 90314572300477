import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {useSelector} from "react-redux";

import {selectCoreValues, selectOpRooms} from "../../../pages/op_management/op_management_selectors";
import useStyles from "../op_manage_canvas.styles";

/**
 * render vertical lines from 00:00 to 24:00
 * @return {JSX}
 */
export const VerticalLines = ({height}) => {
    const {classes} = useStyles();

    const coreValues = useSelector(selectCoreValues);
    const opRooms = useSelector(selectOpRooms);

    const vlines = [];

    if (!coreValues.rowHeight || opRooms.length === 0) return null;
    for (let i = 1; i <= coreValues.canvasEnd; i++) {
        vlines.push(
            <div className={classes.vlines} key={"v-lines-" + i} style={{left: i * coreValues.widthPerHour + "px", height: height}}></div>
        );
    }
    return <Fragment>{vlines}</Fragment>;
};

VerticalLines.propTypes = {
    height: PropTypes.string.isRequired
};

export default VerticalLines;
